
import Vue from 'vue';

import Transfer, { ITransfer } from 'Entities/privatePresenter/Transfer';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import WalletApi from 'Apis/Wallets';
import TransfersRequest from 'Entities/privatePresenter/TransfersRequest';
import { parsePaginationHeaders } from 'Lib/utils/PaginationParser';
import ApiError from 'Entities/ApiError';
import { RecordsTypes } from 'Models/transfersHistory';
import { SUBSCRIBE, UNSUBSCRIBE } from 'Store/v2/PrivateSocketData';
import { UnnecessarySocketNamespaces } from 'Const/socket';

export enum TransferTypes {
    ALL = '',
    TRANSFER = 'transfer',
    WITHDRAW = 'withdrawal',
    DEPOSIT = 'deposit',
}

export interface TransferHistoryUI {
    currentPage: number;
    itemsPerPage: number;
    pages: number;
    totalItemsCount: number;
    transferType: TransferTypes
}

interface IGetTransfersPayload {
    page: number,
    transferType: number,
}

interface Data {
    transfers: Transfer[];
    transferTypes: { label: string, value: string | undefined }[];
    transferHistoryUi: TransferHistoryUI;
}

interface Methods {
    SET_TRANSFER_HISTORY_UI: (data: Partial<TransferHistoryUI>) => void;
    SET_TRANSFERS: (data: Transfer[]) => void;
    UPDATE_TRANSFER: (data: ITransfer) => void;
    getTransferHistory: (data: IGetTransfersPayload) => void;
}

export default Vue.extend<Data, Methods, any>({
    // state
    data() {
        return {
            transfers: [] as Transfer[],
            subscribedOnUiUpdates: false,
            transferTypes: [
                {
                    label: 'All records',
                    value: RecordsTypes.EMPTY_TRANSFER_TYPE_VALUE,
                },
                {
                    label: 'Transfers',
                    value: RecordsTypes.TRANSFER,
                },
                {
                    label: 'Withdrawals',
                    value: RecordsTypes.WITHDRAW,
                },
                {
                    label: 'Deposits',
                    value: RecordsTypes.DEPOSIT,
                },
                {
                    label: 'Rebates',
                    value: RecordsTypes.REBATE,
                },
            ],
            transferHistoryUi: {
                itemsPerPage: 12,
                pages: 1,
                currentPage: 1,
                totalItemsCount: 0,
                transferType: TransferTypes.ALL,
            } as TransferHistoryUI,
        };
    },
    // actions and mutations
    methods: {
        // mutations
        SET_TRANSFER_HISTORY_UI(payload: Partial<TransferHistoryUI>) {
            this.transferHistoryUi = { ...this.transferHistoryUi, ...payload };
        },
        SET_TRANSFERS(payload: Transfer[]) {
            this.transfers = payload;
        },
        UPDATE_TRANSFER(payload: Partial<ITransfer>) {
            try {
                const index = this.transfers.findIndex((t) => t.id === payload.id);
                if (index !== -1) {
                    const data = this.transfers[index].serialize();
                    this.transfers[index] = new Transfer({ ...data, ...payload });
                    this.transfers = [...this.transfers];
                    return;
                }
                if (this.transferHistoryUi.currentPage === 1) {
                    if (new Date(payload.createdAt!).valueOf() > new Date(this.transfers[0].createdAt).valueOf()) {
                        this.transfers = [new Transfer({
                            ...(payload as ITransfer),
                            initiatorType: 'users',
                            quantity: String(payload.quantity),
                            commission: [],
                        }), ...this.transfers];
                    }
                }
            } catch {
                // code crushed because of indexes error
            }
        },
        // actions
        async getTransferHistory(props: IGetTransfersPayload) {
            if (!props) {
                return;
            }
            const { page, transferType } = props;
            const accountId = this.$store.getters['Accounts/activeAccountID'];
            if (!this.subscribedOnUiUpdates) {
                await this.$store.dispatch('VuexEventListener/addActionListener', {
                    type: 'UiActions/needUpdateTransferHistory',
                    callback: () => this.getTransferHistory(props),
                }, { root: true });
                this.subscribedOnUiUpdates = true;
            }
            if (!accountId) {
                await this.$store.dispatch('VuexEventListener/addActionListener', {
                    type: 'Accounts/setActiveAccount',
                    callback: () => this.getTransferHistory(props),
                    once: true,
                }, { root: true });
                return;
            }
            this.transferHistoryUi.currentPage = page;
            let type: ('transfer' | 'deposit' | 'withdrawal' | 'rebate') | undefined;
            switch (transferType) {
                case 1: {
                    type = 'transfer';
                    break;
                }
                case 2: {
                    type = 'withdrawal';
                    break;
                }
                case 3: {
                    type = 'deposit';
                    break;
                }
                case 4: {
                    type = 'rebate';
                    break;
                }
                default: {
                    type = undefined;
                    break;
                }
            }
            try {
                this.$store.commit(SET_LOADING_ON(undefined), { root: true });
                const { data: transfers, headers } = await WalletApi.privateGetTransfers(new TransfersRequest({
                    accountId,
                    type,
                    page,
                    perPage: this.transferHistoryUi.itemsPerPage,
                    includeTotal: true,
                }), true);

                const { total, totalPage } = parsePaginationHeaders(headers ?? new Headers());

                if (total && totalPage) {
                    this.SET_TRANSFER_HISTORY_UI({
                        pages: totalPage || 1,
                        totalItemsCount: total || 1,
                    });
                }
                this.SET_TRANSFERS(transfers);
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during getting transfer history', { root: true });
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined), { root: true });
            }
        },
    },
    mounted() {
        this.$store.commit(SUBSCRIBE({ channel: UnnecessarySocketNamespaces.transfers, callback: this.UPDATE_TRANSFER.bind(this) }));
    },
    beforeDestroy() {
        this.$store.commit(UNSUBSCRIBE({ channel: UnnecessarySocketNamespaces.transfers, callback: this.UPDATE_TRANSFER.bind(this) }));
    },
});
