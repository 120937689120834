
import mixins from 'vue-typed-mixins';

import DepositData from 'Modules/Payment/components/Deposit/Deposit.Data.vue';
import ApiError from 'Entities/ApiError';
import PublicDataApi from 'Apis/PublicData';
import BankRequisitesRequest from 'Entities/publicPresenter/BankRequisitesRequest';
import BankRequisitesResponseData from 'Entities/walletExecutor/BankRequisitesResponseData';
import BankRequisites from 'Entities/publicPresenter/BankRequisites';
import Icon from 'UI/Icon.vue';
import AssetValue from 'UI/AssetValue.vue';
import { copyTextWithPermissions } from 'Lib/utils/copyTextWithPermissions';
import Button from 'Control/Button.vue';
import WalletsApi from 'Apis/Wallets';
import CancelDepositRequestData from 'Entities/walletExecutor/CancelDepositRequestData';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';

type Data = {
    previousRoute: string;
    requisites: null | BankRequisites;
    selectedRequisite: null | BankRequisitesResponseData;
    showWhitelistedWalletDetails: boolean;
};

type Methods = {
    openLink: (data: string) => void;
    goBack: () => void;
    toggleDetails: () => void;
    copy: (text: string | number | undefined, field: string | undefined) => void;
    cancelTransferAndHideModal: () => void;
};

type Computed = {
    infoText: string;
    currentUser: undefined | InternalUserResponse;
};

export default mixins(DepositData).extend<Data, Methods, Computed>({
    components: {
        Button,
        AssetValue,
        Icon,
    },
    mixins: [DepositData],
    data() {
        return {
            previousRoute: '/wallets',
            requisites: null,
            selectedRequisite: null,
            showWhitelistedWalletDetails: true,
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        infoText() {
            if (this.currentUser?.type === 'CORPORATE') {
                return `
                    <span>
                        Fiat deposits must originate only from your company's
                        <br />
                        whitelisted corporate bank accounts.
                        <br />
                        Deposits from third-party or non-whitelisted accounts will be rejected.
                    </span>
                `;
            }
            return `
                <span>
                    Deposits must originate only from your personal, whitelisted bank account.
                    <br />
                    Payments from third-party or non-whitelisted accounts will be rejected.
                </span>
            `;
        },
    },
    methods: {
        openLink(link) {
            window.open(link);
        },
        async goBack() {
            if (this.previousRoute) {
                await this.$router.push(this.previousRoute).catch(() => { /* navigation error */ });
                return;
            }
            this.$router.go(-1);
        },
        toggleDetails() {
            this.showWhitelistedWalletDetails = !this.showWhitelistedWalletDetails;
        },
        copy(text, fieldName) {
            if (text && fieldName) {
                copyTextWithPermissions(String(text), this.$store.dispatch, fieldName);
            }
        },
        async cancelTransferAndHideModal() {
            if (!this.depositUi.transferId) {
                return;
            }

            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                await WalletsApi.cancelDeposit(new CancelDepositRequestData({
                    transferId: this.depositUi.transferId,
                }));
                await this.$store.dispatch('Notificator/showSuccessNotification', 'Deposit has been successfully canceled');
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong');
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Error during cancelling deposit');
                }
            } finally {
                await this.goBack();
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
    },
    async mounted() {
        const {
            depositUi,
            previousRoute,
            requisite,
        } = this.$route.query;
        this.depositUi = {
            ...depositUi,
        };
        this.previousRoute = previousRoute;
        this.selectedRequisite = new BankRequisitesResponseData(requisite);
        try {
            const { data: requisites } = await PublicDataApi.publicGetBankRequisites(new BankRequisitesRequest({
                assetSymbol: this.depositUi.asset,
                providerName: this.depositUi.blockchain,
            }));
            this.requisites = requisites;
        } catch (error) {
            if (error instanceof ApiError) {
                await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error getting bank requisites');
            }
        }
    },
});
